<template>
  <div class="body">
    <div class="body-item left">
      <img class="body-img left" :src="model[0].img"/>
      <div class="body-item-content">
        <div class="title main">{{ model[0].title }}</div>
        <div class="title sub">{{ model[0].subTitle }}</div>
        <div class="desc">
          {{ model[0].desc }}
        </div>
        <a-button class="btn-primary" type="primary" @click="onShowInputModal">
          立即体验
        </a-button>
      </div>
    </div>
    <div class="body-item right">
      <div class="body-item-content">
        <div class="title main">{{ model[1].title }}</div>
        <div class="title sub">{{ model[1].subTitle }}</div>
        <div class="desc">
          {{ model[1].desc }}
        </div>
        <a-button class="btn-primary" type="primary" @click="onShowInputModal">
          立即体验
        </a-button>
      </div>
      <img class="body-img right" :src="model[1].img"/>
    </div>
    <div class="body-item left">
      <img class="body-img left" :src="model[2].img"/>
      <div class="body-item-content">
        <div class="title main">{{ model[2].title }}</div>
        <div class="title sub">{{ model[2].subTitle }}</div>
        <div class="desc">{{ model[2].desc }}</div>
        <a-button class="btn-primary" type="primary" @click="onShowInputModal">
          立即体验
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailBody",
  props: ['model'],
  methods: {
    onShowInputModal() {
      this.$event.$emit("showTryModal");
    },
  }
}
</script>

<style scoped lang="less">

@import "../global.css";

.body {

  .body-item {
    display: flex;
    justify-content: center;
    padding: 50px;

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
      background: #f0f0f0;
    }

    .body-img {
      width: 275px;
    }

    .body-item-content {
      width: 350px;
      padding: 20px;

      .title {
        font-size: 23px;

        &.main {
          color: #222222;
        }

        &.sub {
          color: #258DCF;
        }

      }

      .desc {
        font-size: 12px;
        color: #313131;
      }

      .btn-primary {
        font-weight: bold;
        margin-top: 15px;
        padding: 0 30px;
        height: 25px;
        font-size: 12px;
      }

    }

  }


}
</style>
