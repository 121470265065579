<template>
  <div class="header">
    <router-link class="logo" to="/">BACHASHU</router-link>
    <div class="header-menu">
      <a>
        <router-link to="/product-detail">产品中心</router-link>
      </a>
      <a>
        <router-link to="/service-detail">服务中心</router-link>
      </a>
      <a class="btn-login" @click="goLogin">登录</a>
      <a-button type="primary" @click="onShowTryModal">
        免费试用
      </a-button>
    </div>

    <a-modal
        class="input-modal"
        title="填写信息，预约体验"
        :visible="showTryModal"
        :footer="null"
        :width="400"
        @cancel="cancel">
      <div class="modal-sub-title">请填写以下必要资料，我们的客户运营专家会很快来联系您</div>
      <div>
        <div class="form-title">姓名</div>
        <a-input v-model="name"/>
        <div class="form-title">手机号码</div>
        <a-input v-model="phone"/>
        <div class="form-title">企业名称</div>
        <a-input v-model="orgName"/>
        <br/>
        <br/>
        <a-button class="submit-button" type="primary" :block="true" :loading="confirmLoading" @click="handleOk">
          提 交
        </a-button>
      </div>
    </a-modal>

    <a-modal
        class="success-modal"
        :visible="showSuccessModal"
        :confirm-loading="confirmLoading"
        :footer="null"
        title="登记成功!"
        :width="600"
        @cancel="cancel"
        @ok="handleOk">
      <div class="success-modal-content">
        <div class="d-desc">
          <div class="success-desc-1">
            感谢您的信任。
            <br>
            稍后我们会根据您预留的信息联系您。
          </div>
          <div class="success-desc-2">
            您也可以扫码添加我们的企业微信
            立即咨询，免费试用
          </div>
          <img class="qr-image" :src="require('../assets/img/qrcode.png')"/>
        </div>
        <img class="success-image" :src="require('../assets/img/modal_2.png')"/>


      </div>
    </a-modal>

  </div>
</template>

<script>
export default {
  name: "Header",

  data() {
    return {
      loginUrl: "http://scrm.bachashu.com.cn/login",
      showTryModal: false,
      showSuccessModal: false,
      confirmLoading: false,
      name: "",
      phone: "",
      orgName: ""
    }
  },

  mounted() {
    this.$event.$on("showTryModal", () => {
      this.onShowTryModal()
    });
  },

  methods: {
    handleOk() {

      if (this.name.trim().length === 0) {
        this.$message.error('请填写姓名');
        return;
      }
      if (this.phone.trim().length === 0) {
        this.$message.error('请填写手机号');
        return;
      }
      if (this.orgName.trim().length === 0) {
        this.$message.error('请填写企业名称');
        return;
      }

      this.confirmLoading = true;
      setTimeout(() => {
        this.showTryModal = false;
        this.confirmLoading = false;
        this.showSuccessModal = true;
      }, 2000);
    },

    onShowTryModal() {
      this.showTryModal = true;
    },

    cancel() {
      this.showTryModal = false;
      this.showSuccessModal = false;
    },

    goLogin() {
      window.location.href = this.loginUrl
    }
  }

}
</script>

<style scoped lang="less">

.header {
  justify-content: space-around;
  display: flex;
  margin: 0 auto;

  .logo {
    color: #6d9cf3;
    font-weight: bold;
    font-family: PuHuiTi-Bold, serif;
  }

  .header-menu {
    display: inline-flex;
    align-items: center;

    a {
      display: inline-block;
      width: 60px;
      margin: 0 10px;
      color: #344b59;

      &:hover {
        color: #0577f9;
      }

    }

    .btn-login {
      color: #0577f9;
    }

    .ant-btn {
      background-color: #0577f9;
      font-weight: bold;
    }
  }
}

.input-modal {

  /deep/ .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      color: #1b4d6b;
      text-align: center;
    }
  }

  /deep/ .ant-modal-body {
    padding: 0 24px 24px 24px;
  }

  .modal-sub-title {
    font-size: 13px;
  }

  .form-title {
    margin: 10px 0;
  }

  .submit-button {
    background: #539ae8;
    font-weight: bold;
    border: unset;
  }
}

.success-modal {
  /deep/ .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      color: #258dcf;
      text-align: center;
    }
  }

  /deep/ .ant-modal-body {
    padding: 0 24px 24px 24px;
  }
}

.success-modal-content {
  display: inline-flex;

  .d-desc {

    padding: 6px;

    .success-desc-1 {
      font-size: 15px;
      color: #258dcf;
    }

    .success-desc-2 {
      font-size: 15px;
      color: #1b4d6b;
    }

    .qr-image {
      width: 60px;
      height: 60px;
      display: block;
      margin: 0 auto;
    }
  }

  .success-image {
    display: inline;
    width: 250px;
    height: 160px;
    float: right;
  }


}


</style>
