import Vue from 'vue'
import App from './App.vue'
import {Button, Layout, Tabs, List, Modal, Input, message} from 'ant-design-vue';
import less from 'less'
import './global.css'
import router from './router'

Vue.use(less)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Modal)
Vue.use(Input)
Vue.prototype.$message = message
Vue.config.productionTip = false

Vue.prototype.$event = new Vue()

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
