<template>
  <div>
    <a-layout>
      <a-layout-header>
        <Header/>
      </a-layout-header>
      <a-layout-content>
        <Company/>
        <Product/>
        <Service/>
        <Partner/>
        <ContentOver/>
      </a-layout-content>
      <a-layout-footer>
        <Footer/>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Product from "@/components/Product";
import Company from "@/components/Company";
import Service from "@/components/Service";
import Partner from "@/components/Partner";
import ContentOver from "@/components/ContentOver";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {Header, Company, Product, Service, Partner, ContentOver, Footer}
}
</script>

<style scoped lang="less">
.ant-layout-header {
  background: #f9fbfc;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px;
  position: fixed;
  z-index: 999;
  width: 100%;
  line-height: 50px;
  height: 50px;
}

.ant-layout-content {
  background: white;
  margin-top: 50px;
  display: block;
}

.ant-layout-footer {
  padding: 0;
}

</style>
