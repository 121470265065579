<template>
  <div class="footer">

    <div class="footer-info-container">
      <div class="footer-info basic">
        <img class="logo" :src="require('../assets/logo.png')"/>
        <h1 class="logo-name">
          BACHASHU
        </h1>
        <div class="company-info">
          苏州八叉树信息技术有限公司
          <br/>
          昆山开发区前进东路科技广场504室
          <br/>
          BACHASHU123456@bachashu.com
          <br/>
          000 - 0000 0000
        </div>
      </div>
      <div class="footer-info info">
        <h3>
          产品
        </h3>
        <router-link class="info-name" v-for="i in productList" :key="i" to="/product-detail">
          {{ i }}
        </router-link>
      </div>

      <div class="footer-info info">
        <h3>
          服务
        </h3>
        <router-link class="info-name" v-for="i in serviceList" :key="i" to="/service-detail">
          {{ i }}
        </router-link>
      </div>

      <div class="footer-info info">
        <h3>
          解决方案
        </h3>
        <a class="info-name" v-for="i in solutionList" :key="i">
          {{ i }}
        </a>
      </div>

      <div class="footer-info info">
        <img :src="require('../assets/img/qrcode.png')"/>
        <div>
          扫码加微信
          <br/>
          立即体验
        </div>
      </div>
    </div>
    <div class="record"><a  style='color:white' href="https://beian.miit.gov.cn/">网站备案号:	苏ICP备19071977号-5</a></div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      productList: ["智能SCRM系统", "CMS管理系统", "数据采集系统"],
      serviceList: ["八叉树代运营服务", "专业数字化人才培训服务"],
      solutionList: ["金融保险行业通用解决方案", "婚庆行业整体解决方案", "文创行业数字化解决方案"]
    }
  }
}
</script>

<style scoped lang="less">

.footer {
  background: #032e64;
  color: white;

  .footer-info-container {
    padding: 20px;
    display: inline-flex;
    width: auto;
    justify-content: center;
    border-bottom: #5c6e78 1px solid;

    .basic {
      text-align: left;
      width: 200px;

      .logo {
        width: 120px;
      }

      .logo-name {
        font-size: 35px;
        color: #fff;
        font-family: PuHuiTi-Bold, serif;
      }

      .company-info {
        color: #ffffff;
        font-size: 12px;
      }
    }

    .footer-info.info {
      margin-bottom: 40px;
      text-align: left;
      padding: 20px 20px;

      h3 {
        color: white;
      }

      .info-name {
        font-size: 12px;
        padding: 10px 0;
        color: white;
        display: block;

        &:hover {
          color: #dddddd;
        }

      }

      img {
        width: 50px;
        height: 50px;
      }

      div {
        font-size: 11px;
        margin-top: 10px;
      }
    }
  }

  .bottom-line {
    margin: 0 50px;
    height: 1px;
    display: block;
    background: rgba(255, 255, 255, 0.5);
    border-style: none;
  }

  .record {
    padding: 20px;
    font-size: 10px;
  }


}
</style>
