<template>
  <div class="product">
    <h3>基于大数据分析智能营销产品</h3>
    <h5>八叉树提供从线索获取，到客户管理，潜客挖掘的全流程私域运营工具产品，帮助企业实现数字化营销，提升业绩。</h5>
    <a-tabs default-active-key="2" @change="callback">
      <a-tab-pane key="1" tab="智能SCRM系统">
        <product-item :product-info="productList[0]"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="CMS内容管理系统">
        <product-item :product-info="productList[1]"/>
      </a-tab-pane>
      <a-tab-pane key="3" tab="数据采集系统">
        <product-item :product-info="productList[2]"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";

export default {
  name: "Product",
  components: {ProductItem},

  data() {
    return {
      productList: [
        [
          {
            title: "客户管理",
            desc: "为企业用户提供批量导入线索，批量加好友，自动分配任务；统一查看客户跟进记录，客户详情查询，并提供在职离职客户继承机制"
          },
          {
            title: "客户转化",
            desc: "提供智能话术，客户群发；帮助进行客户分层分类跟进；文章，视频、等素材库内容，孵化客户；并附加互动雷达及自动打标签功能"
          },
          {
            title: "企业风控",
            desc: "提供消息存档、销售聊天内容查看，聊天敏感词检测，敏感动作监控等功能"
          }
        ],
        [
          {
            title: "快速回复私信消息",
            desc: "在一个客服平台上快速回复粉丝私信问题，同时还可将话术库导入系统中，在对话窗口中输入关键词即可快速搜索出响应话术并快速回复给粉丝"
          },
          {
            title: "批量回复评论",
            desc: "支持对抖音企业号视频作品的所有评论进行批量回复，加快互动响应速度和回复效率。用户还可以根据关键词快速定位到高质量评论进行针对性回复"
          },
          {
            title: "AI智能机器人",
            desc: "提供基于NLP自然语言处理算法的智能客服机器人，可以智能识别98%的粉丝的咨询意图并进行相应需求回复，咨询量大的时候可以用机器人来完成快速接待"
          }
        ],
        [
          {
            title: "视频评论采集分析",
            desc: "通过大数据采集技术快速采集抖音账号的视频及评论数据，可通过年龄、所在地区、性别等维度进行筛选和数据分析"
          },
          {
            title: "高价值潜客挖掘",
            desc: "在采集视频及评论数据基础上，通过人工智能分析，深度挖掘潜在客户的行为习惯，帮助企业用户进行精准营销及投放，提升企业用户的ROI"
          }
        ]
      ]
    }
  }


}
</script>

<style scoped lang="less">

.product {
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px -15px;

  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  h5 {
    font-family: PuHuiTi-Regular, serif;
    color: #646a6d;
  }

}

/deep/ .ant-tabs-bar {
  border-bottom: none;
}

/deep/ .ant-tabs-tab {
  padding: 8px 5px;
  margin: 0 45px;
}

/deep/ .ant-tabs-ink-bar {
  height: 5px;
  border-radius: 5px;
  background-color: #8c91eb;;
}

</style>
