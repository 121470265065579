<template>
  <div>
    <a-layout>
      <a-layout-header>
        <Header/>
      </a-layout-header>
      <a-layout-content>
        <DetailHeader :model="detailInfo.header"/>
        <DetailBody :model="detailInfo.body"/>
        <ContentOver/>
      </a-layout-content>
      <a-layout-footer>
        <Footer/>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from "@/components/Header";
import DetailHeader from "@/components/DetailHeader";
import DetailBody from "@/components/DetailBody";
import Footer from "@/components/Footer";
import ContentOver from "@/components/ContentOver";

export default {
  name: "ServiceDetail",
  components: {ContentOver, Footer, DetailBody, DetailHeader, Header},
  data() {
    return {
      detailInfo: {
        header: {
          title: "代运营服务",
          desc: "不聊概念，只谈GMV，以生意增长为向导的私域运营服务商 完美的私域运营组织架构；提供运营、内容、客服、投放四大岗位人员"
        },
        body: [
          {
            img: require("../assets/img/img_5.png"),
            title: "多渠道高效吸粉",
            subTitle: "快速 有效",
            desc: "强大的吸粉渠道资源和数据资源 提供短信、外呼、广告裂变四大吸粉能力"
          },
          {
            img: require("../assets/img/img_7.png"),
            title: "提升粉丝互动效率",
            subTitle: "高曝光 高转化",
            desc: "系统化粉丝互动维护 增加粉丝互动频率 增强信任背书提升曝光和转化率"
          },
          {
            img: require("../assets/img/img_6.png"),
            title: "优化节奏稳中求快",
            subTitle: "更全面 更精细",
            desc: "递进式变现节奏 按照粉丝加入的时间轴精细化运营变现 满足粉丝不同时间场景的需求"
          }
        ],
      }
    }
  }
}
</script>

<style scoped lang="less">
.ant-layout-header {
  background: #f9fbfc;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px;
  position: fixed;
  z-index: 999;
  width: 100%;
  line-height: 50px;
  height: 50px;
}

.ant-layout-content {
  background: white;
  margin-top: 50px;
  display: block;
}

.ant-layout-footer {
  padding: 0;
}

</style>
