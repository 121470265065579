<template>
  <div class="product-item">
    <div class="product-item-center">
      <div>
        <div class="product-info">
          <a-list class="product-list">
            <list-item class="product-intro" v-for="item in productInfo" :key="item">
              <div class="product-intro1">{{ item.title }}</div>
              <div class="product-intro2">{{ item.desc }}</div>
            </list-item>
          </a-list>
          <img class="product-image" :src="require('../assets/img/img_1.png')"/>
        </div>
        <a-button class="btn-primary" type="primary" @click="onShowInputModal">
          立即体验
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",

  props: ['productInfo'],

  data() {
    return {
      products: []
    }
  },

  methods: {
    onShowInputModal() {
      this.$event.$emit("showTryModal");
    },
  }

}
</script>

<style scoped lang="less">
.product-item {
  background: #f3f6f8;
  padding: 16px 0;

  .product-item-center {
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    background: #fff;
    padding: 30px 10px;
    margin: 0 auto;

    .product-info {
      display: inline-flex;

      .product-list {
        width: 450px;

        .product-intro {
          text-align: left;
          padding: 12px 20px;
          display: block;
          margin: 2px 0;

          .product-intro1 {
            color: #1b4d6b;
            font-family: PuHuiTi-Medium, serif;
            font-size: 14px;
          }

          .product-intro2 {
            color: #646a6d;
            max-lines: 2;
            text-overflow: ellipsis;
            font-size: 10px;
          }
        }
      }

      .product-image {
        height: 240px;
        margin-left: 30px;
      }
    }

    .btn-primary {
      display: block;
      margin: 30px auto 0;
      padding: 0 60px;
      font-weight: bold;
    }
  }
}


</style>
