<template>
  <div class="partner">
    <h3>我们的合作伙伴</h3>
    <h5>我们的业务覆盖多行业 多领域</h5>


    <div class="partner-items">
      <div class="partner-item" v-for="i in 4" :key="i">
        <img class="partner-logo" :src="imgs[i-1]">
        <div class="partner-desc"></div>
      </div>
      <div class="arrow-container">
        <a><img class="arrow arrow-left" :src="require('../assets/img/arrow_left.png')"/></a>
        <a><img class="arrow arrow-right" :src="require('../assets/img/arrow_right.png')"/></a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Partner",

  data() {
    return {
      imgs: [
        require('../assets/img/partner_1.png'),
        require('../assets/img/partner_2.png'),
        require('../assets/img/partner_3.png'),
        require('../assets/img/partner_4.png'),
      ]
    }
  }
}
</script>

<style scoped lang="less">

.partner {
  background-image: url('../assets/img/img_3.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  background-size: cover;
  display: inline-block;
  position: relative;

  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  h5 {
    font-family: PuHuiTi-Regular, serif;
    color: #646a6d;
  }


  .partner-items {
    display: inline-flex;
    margin: 25px auto 0;
    position: relative;

    .partner-item {
      background: #fff;
      width: 175px;
      margin: 0 10px;
      padding: 10px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px;

      .partner-logo {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }

      .partner-desc {
        color: #646a6d;
        font-size: 13px;
        font-family: PuHuiTi-Regular, serif;
      }

    }

    .arrow-container {
      position: absolute;
      top: -40px;
      right: 10px;

      .arrow {
        width: 15px;

        &.arrow-right {
          margin-left: 20px;
        }

      }


    }

  }

}
</style>
