import Vue from 'vue'
import Router from 'vue-router'

import Home from "@/view/Home";
import ProductDetail from "@/view/ProductDetail";
import ServiceDetail from "@/view/ServiceDetail";

Vue.use(Router)

export default new Router({
	mode: 'history',
	scrollBehavior: () => ({y: 0}),
	routes: [
		{
			path: '/',
			name: "home",
			component: Home,
		},
		{
			path: '/product-detail',
			name: "product-detail",
			component: ProductDetail,
		},
		{
			path: '/service-detail',
			name: "service-detail",
			component: ServiceDetail,
		}
	]
})
