<template>
  <div>
    <a-layout>
      <a-layout-header>
        <Header/>
      </a-layout-header>
      <a-layout-content>
        <DetailHeader :model="detailInfo.header"/>
        <DetailBody :model="detailInfo.body"/>
        <ContentOver/>
      </a-layout-content>
      <a-layout-footer>
        <Footer/>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Header from "@/components/Header";
import DetailBody from "@/components/DetailBody";
import Footer from "@/components/Footer";
import ContentOver from "@/components/ContentOver";
import DetailHeader from "@/components/DetailHeader";

export default {
  name: "ProductDetail",
  components: {DetailHeader, ContentOver, Footer, DetailBody, Header},

  data() {
    return {
      detailInfo: {
        header: {
          title: "CDP系统",
          desc: "搜集整理企业所有渠道历史用户交易及行为数据，为企业营销提供更好的数据基础服务"
        },
        body: [
          {
            img: require("../assets/img/img_2.png"),
            title: "全渠道会员数据",
            subTitle: "全面 可靠",
            desc: "全面接入企业内外部消费者数据并深度融合 包括微信公众号、电商平台、内部ERP、CRM等"
          },
          {
            img: require("../assets/img/img_1.png"),
            title: "全面深度洞察自动精准营销",
            subTitle: "更精准 更高效",
            desc: "利用行业头部客户的用户运营经验和标签体系 深度洞察，指导实现自动化精准运营"
          },
          {
            img: require("../assets/img/img_4.png"),
            title: "沉淀用户资产持续策略升级",
            subTitle: "更迅速 更持续",
            desc: "通过系列内容和活动运营沉淀的数据资产，利用用户价值模型、活跃度模型、流失模型等，更精准的分层分群，实现针对性的策略升级"
          }
        ],
      }
    }
  }

}
</script>

<style scoped lang="less">
.ant-layout-header {
  background: #f9fbfc;
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px;
  position: fixed;
  z-index: 999;
  width: 100%;
  line-height: 50px;
  height: 50px;
}

.ant-layout-content {
  background: white;
  margin-top: 50px;
  display: block;
}

.ant-layout-footer {
  padding: 0;
}

</style>
