<template>
  <div class="company-desc">
    <div class="d-left">
      <h1>
        苏州八叉树智能科技有限公司
      </h1>
      <h6>
        Suzhou BACHASHU Intelligent Technology Co., Ltd
      </h6>
      <h5>
        公司拥有海量的流量及媒介资源。自有账号矩阵全网粉丝4亿+，小程序端DAU>700w，拥有约8亿用户精准画像；合作KOL超3000人，合作明星艺人超过200人；拥有人民日报、中国经济传媒协会、央广、新华社等央媒代理权，数百家商业媒体以及上百个城市的户外媒体联盟渠道。
      </h5>
      <a-button type="primary" @click="onShowInputModal">
        立即体验
      </a-button>
    </div>
    <img class="company-img" :src="require('../assets/img/img_program.png')"/>
  </div>
</template>

<script>
export default {
  name: "Company",
  methods: {
    onShowInputModal() {
      this.$event.$emit("showTryModal");
    },
  }

}
</script>

<style scoped lang="less">

@import "../global.css";

.company-desc {
  background: #f5f5f5;
  padding-top: 80px;
  box-shadow: rgba(0, 0, 0, 0.05) inset 0 -25px 25px;
  display: flex;
  justify-content: center;


  .d-left {
    text-align: left;
    width: 450px;
  }

  h1 {
    color: #1b4d6b;
    font-family: PuHuiTi-Bold, serif;
    font-size: 32px;
    margin-bottom: 0;
  }

  h6 {
    color: #6d9cf3;
    font-size: 8px;
    font-weight: 400;
  }

  h5 {
    color: #5c6e78;
    font-weight: 400;
    margin: 10px auto 0;
  }

  .ant-btn {
    padding: 0 60px;
    font-weight: bold;
    display: block;
    margin: 30px auto;
  }

  .company-img {
    margin-left: 30px;
    margin-bottom: 50px;
    height: 240px;
  }

}
</style>
