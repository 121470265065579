<template>
  <div class="header">
    <h1>
      {{ model.title }}
    </h1>
    <h6>
      {{ model.desc }}
    </h6>
  </div>
</template>

<script>
export default {
  name: "DetailHeader",
  props: ['model'],
}
</script>

<style scoped lang="less">

@import "../global.css";

.header {
  background: #F0F9F8;
  padding: 50px 0;
  box-shadow: rgba(0, 0, 0, 0.05) inset 0 -25px 25px;

  h1 {
    color: #1b4d6b;
    font-family: PuHuiTi-Bold, serif;
    font-size: 32px;
    margin-bottom: 0;
  }


  h6 {
    color: #258DCF;
    font-family: PuHuiTi-Bold, serif;
    margin: 10px auto 0;
  }

}
</style>
