<template>
  <div class="content-over">
    <h1 class="text text-1">即刻开始高效管理项目</h1>
    <h1 class="text text-2">马上体验全方位高端服务</h1>
    <a-button class="btn-primary" type="primary" @click="onShowInputModal">
      立即体验
    </a-button>
  </div>
</template>

<script>
export default {
  name: "ContentOver",

  methods: {
    onShowInputModal() {
      this.$event.$emit("showTryModal");
    },
  }
}
</script>

<style scoped lang="less">

.content-over {
  background: linear-gradient(0deg, #d7e8f4, #ffffff);

  .text {
    font-family: PuHuiTi-Medium, serif;
    font-size: 25px;

    &.text-1 {
      color: #1b4d6b;
      display: inline-block;
      padding: 0 100px 0 0;
      margin-top: 60px;
    }

    &.text-2 {
      color: #258dcf;
      padding: 0 0 0 110px;
    }
  }

  .btn-primary {
    background: #1b4d6b;
    padding: 0 60px;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: bold;
  }


}

</style>
