<template>
  <div class="service-item">
    <div class="service-item-center">
      <div>
        <div class="service-info">
          <img class="service-image" :src="require('../assets/img/img_2.png')"/>
          <a-list class="service-list">
            <list-item class="service-intro" v-for="item in serviceInfo" :key="item">
              <div class="service-intro1">{{ item.title }}</div>
              <div class="service-intro2">{{ item.desc }}</div>
            </list-item>
          </a-list>
        </div>
        <a-button class="btn-primary" type="primary" @click="onShowInputModal">
          申请服务
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceItem",

  props: ["serviceInfo"],

  data() {
    return {
      data: []
    }
  },

  methods: {
    onShowInputModal() {
      this.$event.$emit("showTryModal");
    },
  }

}
</script>

<style scoped lang="less">
.service-item {

  .service-item-center {
    margin: 0 auto;
    border-radius: 8px;

    .service-info {
      display: flex;
      justify-content: center;

      .service-list {
        width: 440px;

        .service-intro {
          text-align: left;
          padding: 12px 20px;
          display: block;
          margin: 2px 0;

          .service-intro1 {
            color: #1b4d6b;
            font-family: PuHuiTi-Medium, serif;
            font-size: 14px;
          }

          .service-intro2 {
            color: #646a6d;
            max-lines: 2;
            text-overflow: ellipsis;
            font-size: 10px;
          }
        }
      }

      .service-image {
        height: 280px;
        margin-right: 30px;
      }
    }

    .btn-primary {
      display: block;
      background-color: #5852d6;
      margin: 40px auto;
      font-weight: bold;
      padding: 0 60px;
    }
  }
}


</style>
