<template>
  <div class="service">
    <h3>八叉树提供代运营服务及人才培训服务</h3>
    <h5>提供专业全域代运营服务及数字化人才培训服务，提升客户转化率并不断满足企业用人需求</h5>
    <a-tabs default-active-key="2" @change="callback">
      <a-tab-pane key="1" tab="八叉树全域代运营服务">
        <service-item :service-info="serviceList[0]"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="专业数字化人才培训服务">
        <service-item :service-info="serviceList[1]"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ServiceItem from "@/components/ServiceItem";

export default {
  name: "Service",
  components: {ServiceItem},
  data() {
    return {
      serviceList: [
        [
          {
            title: "持续涨粉、提高转化效率",
            desc: "通过贯穿从定位、选题、脚本、拍摄、后期包装、运营、投放等全流程的精细化运营，确保目标账号进入该区域（省域内或者市域内）同类别账号的的前列"
          },
          {
            title: "精细化运营，提升区域、行业影响力",
            desc: "对于培育完成的头部账号，进行持续性的运营指导，，同时借助八叉树SCMS、SCRM、CDP等客户管理及营销系统赋能销售，提升私域销售转化率"
          }
        ],
        [
          {
            title: "批量培训新人，快速达到运营能力标准",
            desc: "通过标准培训，确保经验单薄的新人能够熟悉平台规则，并在此基础上掌握短视频剪辑、制作、发布的基础能力及直播运营操作"
          },
          {
            title: "筛选达人，持续赋能",
            desc: "伴随培训的深入，筛选出具有潜质的达人，并从平台深度玩法、流量扶持、活动、技巧等层面进行综合扶持，助力销售人员能力进阶，从而提升账号运营效率和效果"
          }
        ]
      ]
    }
  }


}
</script>

<style scoped lang="less">

.service {

  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  h5 {
    font-family: PuHuiTi-Regular, serif;
    color: #646a6d;
  }

}

/deep/ .ant-tabs-bar {
  border-bottom: none;
}

/deep/ .ant-tabs-tab {
  padding: 8px 5px;
  margin: 0 45px;
}

/deep/ .ant-tabs-ink-bar {
  height: 5px;
  border-radius: 5px;
  background-color: #8c91eb;;
}

</style>
